.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.container {
  margin-top: 100px;
  margin-bottom: 50px;
}
.tableRequisites {
  width: 100%;
  max-width: 300px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: visible;
  border: 3px solid #0034ff;
  border-radius: 20px;
  padding: 5px;
  overflow: hidden;
  transition: max-height 2s ease-in-out;
}
.hiddenTableRequisites {
  composes: tableRequisites;
  max-height: 300px;
  transition: max-height 0.5s ease-in-out;
}
.title {
  display: block;
  width: 100%;
  font-family: Verdana, Tahoma, sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.text {
  composes: title;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 30px;
}
.header {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 50px;
  font-family: Verdana, Tahoma, sans-serif;
}
.button {
  height: 60px;
  display: block;
  margin: 0 auto;
  top: 400px;
  z-index: 3;
  left: 0;
  right: 0;
  cursor: pointer;
  background: none;
  font-weight: 500;
  border: none;
  font-size: 25px;
  font-family: Verdana, Tahoma, sans-serif;
}

@media (min-width: 500px) {
  .tableRequisites {
    max-width: 400px;
    padding: 20px;
  }
}

@media (min-width: 800px) {
  .container {
    margin-top: 200px;
  }

  .tableRequisites {
    max-width: 700px;
    height: auto;
  }
  .hiddenTableRequisites {
    max-width: 700px;
  }
}
@media (min-width: 1200px) {
  .tableRequisites {
    max-width: 1100px;
  }
}
