.root {
  width: 100%;
  height: 50px;
  background: rgb(9, 9, 121);
  background: linear-gradient(
    0deg,
    rgba(9, 9, 121, 0) 0%,
    rgba(0, 52, 255, 1) 100%
  );
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 20px;
  z-index: 20;
}

@media (min-width: 800px) {
  .modalVisible {
    display: none !important;
  }
}
