.root {
  perspective: 1000px;
  overflow: hidden;
}
.layers_container {
  height: 100vh;
  position: relative;
  transform-style: preserve-3d;
}
.logo {
  width: 230px;
  margin-left: 10px;
  transform: translateZ(300px);
}

.layer {
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  inset: -5vw;
  transform: rotateX(0deg) rotateY(0deg);
  will-change: transform;
  transition: transform 8s ease-in-out;
}
.layer_1 {
  composes: layer;
  transform: translateZ(-100px) scale(1.3);
}
.layer_dark {
  composes: layer;
  width: 100%;
  transform: translateZ(100px) scale(1.5);
  background-color: rgba(0, 0, 0, 0.3);
}
.layer_2 {
  composes: layer;
  transform: translateZ(40px) scale(1);
  filter: blur(3px);
}
.layer_3 {
  width: 100%;
  height: 100%;
  transform: translateZ(150px) scale(1.5);
}
.layer_text {
  composes: layer;
  transform: translateZ(200px);
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 5s ease-in-out;
  margin: 0 auto;
}
.arrow {
  composes: layer;
  transform: translateZ(205px);
  z-index: 9;
  top: 300px;
  cursor: pointer;
}
@media (min-width: 500px) {
  .logo {
    width: 380px;
  }
}
@media (min-width: 600px) {
  .logo {
    width: 430px;
  }
}

@media (min-width: 800px) {
  .logo {
    width: 560px;
    margin-left: 0;
  }
  .layer_2 {
    filter: blur(0px);
  }
}
@media (min-width: 1200px) {
  .logo {
    width: 860px;
  }
}
