.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container {
  width: 100%;
  max-width: 1200px;
  padding-top: 50px;
  margin: 0 auto;
}
.img {
  width: 300px;
}
.text {
  width: 100%;
  max-width: 300px;
  font-size: 16px;
  font-weight: 600;
  text-align: start;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: 30px;
  color: black;
}
.VisibleBlock {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}
.VisibleGeography {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.map {
  display: none;
}

@media (min-width: 1000px) {
  .age {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .VisibleBlock {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
    transition: padding-top 0.5s ease-in-out;
  }
  .UnVisibleBlock {
    composes: VisibleBlock;
    padding-top: 150px;
  }
  .text {
    text-align: start;
    font-size: 20px;
    max-width: 400px;
    margin-top: 30px;
  }
  .img {
    width: 400px;
  }
  .VisibleGeography {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-top: 30px;
    transition: padding-top 0.5s ease-in-out;
    position: relative;
  }
  .text_geography {
    composes: text;
    font-size: 20px;
    max-width: 500px;
  }
  .UnVisibleGeography {
    composes: VisibleGeography;
    padding-top: 150px;
  }
}
