.root {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 100px;
}

.header {
  display: block;
  margin: 0 auto;
  font-size: 28px;
  max-width: 600px;
  text-align: center;
  font-weight: 600;
  font-family: Verdana, Tahoma, sans-serif;
  color: black;
}
