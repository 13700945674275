.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  width: 100%;
  flex: 1 0 auto;
  margin: 0 auto;
  max-width: calc(var(--container-width) + 4 * var(--padding));
}

.footer {
  flex-shrink: 0;
}
