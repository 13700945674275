.container {
  width: 100%;
  max-width: 1100px;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
}
