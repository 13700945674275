.root {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.selectRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 3px solid black;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}
.title {
  font-size: 20px;
  font-weight: 500;
  font-family: Verdana, Tahoma, sans-serif;
  color: black;
}
.contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-top: 10px;
  margin-bottom: 50px;
  gap: 20px;
}
.unVisibleContacts {
  display: none;
  margin-top: 0px;
}
.email {
  font-size: 20px;
  font-weight: 300;
  font-family: Verdana, Tahoma, sans-serif;
}
.tel {
  composes: email;
  color: rgb(0, 52, 255);
}
.block {
  width: 100%;
}

@media (min-width: 800px) {
  .root {
    flex-direction: row;
    align-items: start;
    max-width: 600px;
  }
  .selectRow:hover {
    background: rgba(0, 52, 255, 0.3);
  }
}
