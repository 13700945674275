.root {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 52, 255);
  z-index: 22;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: margin-left 0.5s ease-in-out;
  left: 100%;
}

.root_fadeIn {
  composes: root;
  animation: 0.5s linear 0s 1 normal forwards running fadeIn;
}

.root_fadeOut {
  composes: root;
  animation: 0.5s linear 0s 1 reverse forwards running fadeOut;
}

.menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.listLinks {
  list-style: none;
  color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blockFeedback {
  margin-top: 30px;
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: space-between;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 2px solid white;
  border-radius: 50%;
  background: none;
}

.link {
  display: flex;
  color: white;
  text-decoration: none;
  font-size: 30px;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding: 15px 30px;
}

.burgerIconClose {
  position: absolute;
  top: 10px;
  right: 25px;
}

.sign_out_button {
  composes: link;
}

@keyframes fadeIn {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}

@keyframes fadeOut {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}
