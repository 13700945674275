@import "swiper/css";
@import "./swiper-custom.css";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: black;
}

html,
body,
#root {
  height: 100%;
}
