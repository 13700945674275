.arrowCta {
  display: block;
  height: 13px;
  width: 13px;
  border: 11px solid transparent;
  transform: rotate(45deg);
  position: relative;
  margin: 30vh auto;
}

.arrowCta:after,
.arrowCta:before {
  content: "";
  display: block;
  height: inherit;
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

.arrowCta:after {
  border-bottom: 3px solid rgb(255, 255, 255);
  border-right: 3px solid rgb(255, 255, 255);
  top: 0;
  left: 0;
  opacity: 1;
  animation: bottom-arrow 1.65s infinite;
}

@keyframes bottom-arrow {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  45% {
    opacity: 0;
    transform: translate(12px, 12px);
  }
  46% {
    opacity: 0;
    transform: translate(-16px, -16px);
  }
  90% {
    opacity: 1;
    transform: translate(-6px, -6px);
  }
  100% {
    opacity: 1;
    transform: translate(-6px, -6px);
  }
}

.arrowCta:before {
  top: 0;
  left: 0;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
  animation: top-arrow 1.65s infinite;
}

@keyframes top-arrow {
  0% {
    transform: translate(-6px, -6px);
  }
  35% {
    transform: translate(0, 0);
  }
  90% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@media (min-width: 800px) {
  .arrowCta {
    margin: 40vh auto;
  }
}

@media (min-width: 1900px) {
  .arrowCta {
    margin: 50vh auto;
  }
}
