.navMobile {
  display: flex;
  position: fixed;
}

.navDesktop {
  display: none !important;
}

@media (min-width: 800px) {
  .navMobile {
    display: none !important;
  }
  .navDesktop {
    display: block !important;
  }
}
