.root {
  width: 100%;
  background-color: #0034ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav {
  width: 100%;
  margin: 0 auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding-top: 30px;
  gap: 10px;
  margin-bottom: 20px;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}
.contacts_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.contact_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.link {
  color: white;
  font-size: 20px;
  font-weight: 300;
  height: 30px;
  font-family: Verdana, Tahoma, sans-serif;
  cursor: pointer;
  border-bottom: solid rgba(255, 255, 255, 0);
  transition: border-bottom 0.3s ease-in-out;
  width: 100px;
  text-decoration: none;
}
.contact {
  color: white;
  font-size: 16px;
  font-weight: 300;
  font-family: Verdana, Tahoma, sans-serif;
  margin-top: 15px;
  margin-bottom: 30px;
}
.logo {
  width: 200px;
  margin-top: 50px;
}

@media (min-width: 600px) {
  .root {
    height: 500px;
    padding-top: 20px;
    justify-content: start;
  }
  .container {
    padding-top: 20px;
    max-width: 500px;
  }
  .contacts_container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  .contact_block {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .contact {
    margin: 0;
  }
  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0;
    max-width: none;
  }
  .logo {
    width: 300px;
    margin-top: 30px;
  }
}

@media (min-width: 1200px) {
  .root {
    height: 200px;
  }
  .container {
    flex-direction: row;
    max-width: 1100px;
    justify-content: space-between;
    align-items: start;
  }
  .nav {
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
    max-width: 200px;
    padding-top: 0;
  }
  .contacts_container {
    margin-top: 0;
    align-items: start;
  }
  .logo {
    margin-top: 0;
  }
  .contact {
    margin-left: 10px;
  }
}
